import React from "react";
import { Link, graphql } from "gatsby";

import {MainLayout} from "../components/layouts/MainLayout";
import SEO from "../components/seo";
import Header from '../components/header/Header';

import style from './blog.module.css';
import { Post } from "../components/post/Post";

export default function Blog ({ data }) {
  const { allMarkdownRemark } = data; // data.markdownRemark holds your post data
  // const { frontmatter, html } = allMarkdownRemark;

  const posts = allMarkdownRemark.edges.map((data) => data.node);


  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <MainLayout>
        <SEO title="Blog" />
        <main>
          {posts.map((post, i) => (
            <article className={style.postWrapper}>
              <Post key={`post_${i}`} title={post.title} content={post.excerpt} date={post.frontmatter.date} tags={post.frontmatter.tags}>
                <a className={style.postHeaderLink} href={post.frontmatter.path}>
                  {post.frontmatter.title}
                </a>
              </Post>
              <a className={style.postHeaderLink} href={post.frontmatter.path}>
                Read on...
              </a>
              <hr/>
            </article>
          ))}
        </main>
        <Link to="/">Go back to the homepage</Link>
      </MainLayout>
    </>
  );
};

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      limit: 10,
      filter: {frontmatter: {isPublished: {eq: true}}, excerpt: {}}
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(format: HTML, pruneLength: 200, truncate: true)
          frontmatter {
            path
            title
            date
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
